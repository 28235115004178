import React, { useEffect, useContext } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import {
	Container,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Hero } from '../components/General/Hero';
import { HeroImageRight } from '../components/General/HeroImageRight';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { WaveDownSVG } from '../components/WaveSVGs/WaveDownSVG';
import { SEO } from '../components/SEO';
import { Testimonials } from '../components/Testimonials';
import { CustomerLogos } from '../components/CustomerLogos';
import { Resources } from '../components/General/Resources';
import { HeroVideo } from '../components/General/HeroVideo';
import { WhyPestpacBody } from '../components/WhyPestpac/WhyPestpacBody';
import { CtaCard } from '../components/General/CtaCard';
import LitePageContext from '../context/LitePageContext';
const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
	heroVideoCont: {
		marginBottom: '4rem',
		[theme.breakpoints.down('md')]: {
			marginBottom: '2rem',
		},
	},
	formCont: {
		padding: '16rem 0',

		[theme.breakpoints.down('xs')]: {
			padding: '6rem 0 8rem 0',
		},
	},
}));

const Lite = ({ data, location }) => {
	const lite = data.lite.edges[0].node;
	const classes = useStyles();

	const { setIsLite } = useContext(LitePageContext);

	useEffect(() => {
		setIsLite(true);

		return () => {
			setIsLite(false);
		};
	}, []);

	const {
		metaDescription,
		metaTitle,
		contactForm,
		formImages,
		formBgImage,
		pardotUrl,
		resourceTitle,
		resources,
		_rawResourceBody,
		heroAlignCenter,
		heroVideo,
		hero,
		heroImage,
    _rawIntroText,
    highlightCards,
    softwareFeatureListHeader,
    softwareFeatures,
    caseStudy,
    testimonialTitle,
    testimonial,
    customerLogos,
    customerLogosHeader,
	} = lite;

	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />
			{heroAlignCenter && heroVideo ? (
				<div className={classes.heroVideo}>
					<Hero hero={hero} feature fs />
					<Container>
						<HeroVideo
							wistiaLink={heroVideo}
							button={hero.ctaButtonText || hero.ctaButtonLink}
						/>
					</Container>
				</div>
			) : heroAlignCenter ? (
				<Hero hero={hero} feature />
			) : (
				<HeroImageRight
					hero={hero}
					heroImage={heroImage?.asset?.gatsbyImageData}
					feature
					lite
				/>
			)}

      {caseStudy && (
        <Container>
          <CtaCard
            header={caseStudy.ctaHeader}
            background={caseStudy.ctaBackground}
            subheader={caseStudy.ctaSubheader}
            ctaText={caseStudy.ctaText}
            ctaLink={caseStudy.ctaLink}
            internalLink={caseStudy.internalLink}
            lite
          />
        </Container>
      )}
      <WhyPestpacBody
				// style={{position: 'relative', zIndex: '9'}}
				intro={_rawIntroText}
				highlightCards={highlightCards}
				softwareFeatureListHeader={softwareFeatureListHeader}
				softwareFeatures={softwareFeatures}
			/>

      <div className={classes.testimonialContainer}>
				<Testimonials
					header={testimonialTitle}
					testimonials={testimonial}
					// formRef={formRef}
					// background
				/>
			</div>
			<Container>
				<CustomerLogos
					customerLogosArray={customerLogos}
					customerLogosHeader={customerLogosHeader}
				/>
			</Container>

			<div>
				<WaveDownSVG height='213' width='100%' fill='white' />
			</div>

			<div
				className={classes.formCont}
				style={{
					backgroundImage: `url(${formBgImage?.asset?.gatsbyImageData?.images.fallback.src})`,
					backgroundSize: 'cover',
				}}>
				<Form
					pardotUrl={pardotUrl}
					contactForm={contactForm}
					privacy
					modal={false}
					formImages={formImages}
					location={location}
				/>
			</div>
			<WaveUpSVG height='213' width='100%' fill='white' />
			<Container>
				<Resources
					header={resourceTitle}
					subheader={_rawResourceBody}
					resources={resources}
					noWave
				/>
			</Container>
			<div>
				<WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
			</div>
		</>
	);
};

export const query = graphql`
	query LitePageQuery {
		lite: allSanityLite {
      edges {
        node {
          title
          metaTitle
          metaDescription
          slug {
            current
          }
          hero {
            _rawContent
            backgroundImage {
              asset {
                gatsbyImageData(placeholder: BLURRED, height: 100)
              }
            }
            ctaButtonText
            ctaButtonLink
            internalLink
          }
          heroImage {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          heroVideo
          heroAlignCenter
          _rawIntroText
          highlightCards {
            title
            icon
            header
            accentColor {
              hexValue
            }
            _rawContent
            ctaText
            ctaLink
          }
          softwareFeatureListHeader
          softwareFeatures {
            title
            comparisonHeader
            featureListCta
            logos {
              asset {
                gatsbyImageData
              }
            }
            alternateTextHeaders
            features {
              featureTitle
              featureDescription
              colOne
              colTwo
              colThree
            }
            expandedListHeader
            expandedListSubheader
            expandText
            hideText
            softwareLogoAndDescription {
              softwareComparisonTitle
              logos {
                asset {
                  gatsbyImageData(placeholder: BLURRED, height: 85)
                }
              }
            }
          }
          testimonialTitle
          testimonial {
            title
            header
            testimonialLogo {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            company
            testimonialText
            nameAndTitle
            videoVariant
            image {
              asset {
                gatsbyImageData(fit: FILLMAX, height: 450, width: 775, placeholder: BLURRED)
              }
            }
          }
          testimonialBackground {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          resourceTitle
          _rawResourceBody
          resources {
            title
            blurb
            image {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            ctaText
            ctaLink
          }
          formBgImage {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          contactForm {
            header
            privacyPolicy
          }
          pardotUrl
          customerLogosHeader
          customerLogos {
            logo {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          caseStudy {
            ctaBackground {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            ctaHeader
            ctaLink
            ctaSubheader
            ctaText
            internalLink
          }
        }
      }
		}
	}
`;

export default Lite;
